@font-face {
  font-family: 'ABCDiatype';
  src:
    url('./assets/fonts/ABCDiatype-Medium.woff') format('woff'),
    url('./assets/fonts/ABCDiatype-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IvarText';
  src:
    url('./assets/fonts/IvarText-Italic.otf') format('otf'),
    url('./assets/fonts/IvarText-Regular.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

.material-icons {
  font-family: 'Material Icons' !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'IvarText', sans-serif !important;
}

html {
  font-size: 16px;
  font-family: 'ABCDiatype', sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/*TODO: Move color to theme waiting for createTheme component from storybook*/

.pool-green {
  color: #00b3a6;
  font-size: 16px;
}

/*TODO: Move color to theme waiting for createTheme component from storybook*/

$xs: 4px !important;
$sm: 8px !important;
$md: 12px !important;
$lg: 16px !important;
$xl: 20px !important;
$xxl: 24px !important;
$xxl2: 40px !important;
$xxl3: 48px !important;
$xxl4: 80px !important;

.p-xs {
  padding: $xs;
}

.p-sm {
  padding: $sm;
}

.p-md {
  padding: $md;
}

.p-lg {
  padding: $lg;
}

.p-xl {
  padding: $xl;
}

.pxxl {
  padding: $xxl;
}

.pr-xs {
  padding-right: $xs;
}

.pr-sm {
  padding-right: $sm;
}

.pr-md {
  padding-right: $md;
}

.pr-lg {
  padding-right: $lg;
}

.pr-xl {
  padding-right: $xl;
}

.pr-xxl {
  padding-right: $xxl;
}

.pl-xs {
  padding-left: $xs;
}

.pl-sm {
  padding-left: $sm;
}

.pl-md {
  padding-left: $md;
}

.pl-lg {
  padding-left: $lg;
}

.pl-xl {
  padding-left: $xl;
}

.pl-xxl {
  padding-left: $xxl;
}

.pt-xs {
  padding-top: $xs;
}

.pt-sm {
  padding-top: $sm;
}

.pt-md {
  padding-top: $md;
}

.pt-lg {
  padding-top: $lg;
}

.pt-xl {
  padding-top: $xl;
}

.pt-xxl {
  padding-top: $xxl;
}

.pt-2xxl {
  padding-top: $xxl2;
}

.pb-xs {
  padding-bottom: $xs;
}

.pb-sm {
  padding-bottom: $sm;
}

.pb-md {
  padding-bottom: $md;
}

.pb-lg {
  padding-bottom: $lg;
}

.pb-xl {
  padding-bottom: $xl;
}

.pb-xxl {
  padding-bottom: $xxl;
}

.pb-2xxl {
  padding-bottom: $xxl2;
}

.mt-xs {
  margin-top: $xs;
}

.mt-sm {
  margin-top: $sm;
}

.mt-md {
  margin-top: $md;
}

.mt-lg {
  margin-top: $lg;
}

.mt-xl {
  margin-top: $xl;
}

.mt-xxl {
  margin-top: $xxl;
}

.mt-2xxl {
  margin-top: $xxl2;
}

.mt-4xxl {
  margin-top: $xxl4;
}

.mb-xs {
  margin-bottom: $xs;
}

.mb-sm {
  margin-bottom: $sm;
}

.mb-md {
  margin-bottom: $md;
}

.mb-lg {
  margin-bottom: $lg;
}

.mb-xl {
  margin-bottom: $xl;
}

.mb-xxl {
  margin-bottom: $xxl;
}

.mr-xs {
  margin-right: $xs;
}

.mr-sm {
  margin-right: $sm;
}

.mr-md {
  margin-right: $md;
}

.mr-lg {
  margin-right: $lg;
}

.mr-xl {
  margin-right: $xl;
}

.mr-xxl {
  margin-right: $xxl;
}

.border-radius-lg {
  border-radius: $lg;
}

.card-xs {
  @extend .border-radius-lg;
  @extend .mt-lg;
  min-height: 175px;
  @extend .p-lg;
}

.card-md {
  @extend .border-radius-lg;
  @extend .pt-2xxl;
  @extend .pb-2xxl;
}

.card-xxl {
  @extend .border-radius-lg;
  @extend .mt-lg;
  @extend .pt-lg;
  @extend .pr-lg;
}

.accordion {
  border-radius: 16px !important;
  box-shadow: none !important;
}

.accordion:before {
  background-color: #ffffff !important;
}

.organization-table {
  thead {
    tr {
      background-color: #f6f6f6 !important;
      border-radius: $sm;
    }

    th:first-child {
      border-top-left-radius: $sm;
      border-bottom-left-radius: $sm;
    }

    th:last-child {
      border-top-right-radius: $sm;
      border-bottom-right-radius: $sm;
    }
  }

  th {
    border-bottom: none !important;
  }

  td {
    border-bottom: none !important;
  }

  tbody {
    tr:hover {
      background-color: rgba(242, 231, 254, 0.3) !important;
    }

    tr {
      border-radius: $sm;
    }

    td:first-child {
      border-top-left-radius: $sm;
      border-bottom-left-radius: $sm;
    }

    td:last-child {
      border-top-right-radius: $sm;
      border-bottom-right-radius: $sm;
    }
  }
}

.dropdownchip {
  display: flex;
  align-items: center;
}

.dropdownchip .MuiInput-underline.MuiInputBase-formControl {
  margin-top: 0px !important;
}

.dropdownchip .MuiInputBase-formControl:after,
.dropdownchip .MuiInput-root.MuiInput-underline::before {
  display: none !important;
}

.MuiAutocomplete-popper {
  li.MuiAutocomplete-option[role='option'][aria-selected='true'] {
    background: white;
    color: black;
  }

  li.MuiAutocomplete-option.Mui-focused[role='option'][aria-selected='true'] {
    background: #c9bfe9;
    color: black;
  }

  li.MuiAutocomplete-option.Mui-focused[role='option'][aria-selected='false'] {
    background: rgb(0, 0, 0, 0.1);
    color: black;
  }
}

.table-row-disabled {
  opacity: 0.6;
}
